<template>
  <div>
    <v-snackbar v-model="snackbar" :multi-line="true" :timeout="-1" top class="pa-4">
      <h1 style="text-align: center; line-height: 32px;" class="encodeFont">Geplante Wartungsarbeiten!</h1>
      <br /><br />

      <p style="text-align: center; color: white" class="mb-0">am 24.01.2025 ab 11:00 Uhr wird das System gewartet. 
      <br>
      In dieser Zeit kann es zu systembedingten Ausfällen kommen.</p>

      <template v-slot:action="{ attrs }">
        <v-btn class="pt-0 mb-2" text v-bind="attrs" @click="snackbar = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "maintenance",

  data() {
    return {
      snackbar: true,
    };
  },
};
</script>
